<template>
  <nav v-if="userLogin" class="navbar navbar-expand-lg">
    <img src="../assets/iconGereja.png" alt />
    <a class="navbar-brand" href="/">KIBAID</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="/">
            Dashboard
            <span class="sr-only">(current)</span>
          </a>
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >Tambahkan</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="/input">Transaksi</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >Laporan</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="/report/transaksi">Seluruh Transaksi</a>
            <a class="dropdown-item" href="/report/lokal">Lokal</a>
            <a class="dropdown-item" href="/report/sinode">Sinode / Klasis</a>
            <a class="dropdown-item" href="/report/kasgereja">Kas Gereja</a>
          </div>
        </li>
      </ul>
      <!-- kanan -->
      <div class="username">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-user"></i>
              {{namaJemaat}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Ubah Password</a>
              <a class="dropdown-item" @click.prevent="logout">Keluar</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <router-view /> -->
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    logout() {
      localStorage.removeItem("access_token");
      this.$router.push("/login");
      this.$store.commit("setUserLogin", false);
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.isLogin;
    },
    namaJemaat() {
      return localStorage.getItem("namaJemaat");
    }
  },
  created() {
    if (localStorage.getItem("access_token")) {
      this.$store.commit("setUserLogin", true);
    }
  }
};
</script>

<style>
nav img {
  width: 50px;
  margin-right: 10px;
}
nav {
  background-color: #7046cc;
  color: #ffffff;
  min-height: 100px;
}
.navbar-brand {
  font-size: 40px;
  font-weight: bold;
}
nav a {
  font-size: 16px;
  color: #ffffff;
}
.dropdown-menu.show {
  background-color: #7046cc;
}
.dropdown-item {
  background-color: #7046cc;
  color: #ffffff;
}

#navbarSupportedContent {
  padding: 0;
}
@media screen and(min-width: 850px) {
  nav {
    min-height: 3000px;
    background-color: wheat;
  }
}
</style>