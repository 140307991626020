<template>
  <div class="LoginPage">
    <img src="../assets/logoKibaid.png" alt />
    <form @submit.prevent="login">
      <input type="text" v-model="username" placeholder="username" />
      <input type="password" v-model="password" placeholder="password" />
      <button style="submit" class="btn btn-primary">Login</button>
    </form>
  </div>
</template>

<script>
import axios from "../API/axios";
import Swal from "sweetalert2";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });
      return axios
        .post("/user/login", {
          username: this.username,
          password: this.password
        })
        .then(({ data }) => {
          console.log(data.Jemaat);
          this.$store.commit("setUserLogin", true);
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("namaJemaat", data.Jemaat);
          this.$router.push("/input");
          Toast.fire({
            icon: "success",
            title: "Syalom Dan Selamat Datang!"
          });
        })
        .catch(err => {
          Swal.fire("Maaf", "Anda Buklan Bagian Dari Kami", "error");
        });
    }
  }
};
</script>

<style scoped>
.LoginPage {
  display: grid;
  align-content: center;
  min-height: 100vh;
  justify-content: center;
  justify-items: center;
  /* align-items: center; */
  /* background-color: aqua; */
}
.LoginPage img {
  max-width: 200px;
}
.LoginPage form {
  display: grid;
  margin-top: 10px;
}

form input {
  background-color: #f2f2f2;
  margin-top: 5px;
  border: none;
  text-align: center;
}

form input {
  font-size: 18px;
  color: rgb(10, 0, 0);
}

form button {
  margin-top: 20px;
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  .LoginPage img {
    max-width: 120px;
  }
  .LoginPage form {
    display: grid;
    margin-top: 10px;
  }
  form button {
    margin-top: 10px;
    font-size: 12px;
  }
  form input {
    font-size: 14px;
    color: rgb(10, 0, 0);
  }
}
</style>