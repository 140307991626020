<template>
  <div class="SinodeKlasis">
    <!-- line titel -->
    <div class="title col justify-content-md-center mt-1">
      <div class="title">Laporan Keuangan Klasis / Sinode</div>
      <div class="periode row justify-content-md-center mt-1">
        <div class="col-lg-2">
          Periode:
          <vue-monthly-picker v-model="selectedMonth"></vue-monthly-picker>
        </div>
      </div>
    </div>
    <!-- Line 1 -->
    <div class="row justify-content-md-center">
      <div class="col col-lg-6 justify-content-md-center">
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table">
              <th
                scope="col"
                colspan="3"
              >A. Penerimaan Persepuluhan, Kolekte, Hulu Hasil dan Hulu Adonan :</th>
            </tr>
            <tr class="table-success">
              <th scope="col">No</th>
              <th scope="col">Jenis Persembahan</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Persepuluhan</td>
              <td id="uang">{{ formatUang(perpuluhan()) }}</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Ibadah Hari Minggu</td>
              <td id="uang">{{ formatUang(hariMinggu()) }}</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Ibadah Syukuran</td>
              <td id="uang">{{ formatUang(syukuran()) }}</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Ibadah Rumah Tangga</td>
              <td id="uang">{{ formatUang(rumahTangga()) }}</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Ibadah Hari Raya Gerejawi</td>
              <td id="uang">{{ formatUang(hariRaya()) }}</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Ibadah PSM</td>
              <td id="uang">{{ formatUang(ibadahPsm()) }}</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>Ibadah PKM</td>
              <td id="uang">{{ formatUang(ibadahPkm()) }}</td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>Ibadah PKW</td>
              <td id="uang">{{ formatUang(ibadahPkw()) }}</td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>Ibadah PKP</td>
              <td id="uang">{{ formatUang(ibadahPkp()) }}</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>Hulu Hasil</td>
              <td id="uang">{{ formatUang(ibadahHuluHasil()) }}</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>Hulu Adonan</td>
              <td id="uang">{{ formatUang(ibadahHuluAdonan()) }}</td>
            </tr>
            <tr class="table-secondary">
              <th colspan="2" scope="row">Jumlah</th>

              <th id="uang">{{ formatUang(jumlahPersemBulanan()) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col col-lg-6 justify-content-md-center">
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table">
              <th scope="col" colspan="3">B. Penerimaan Persembahan Natura dan Persembahan Khusus :</th>
            </tr>
            <tr class="table-success">
              <th scope="col">No</th>
              <th scope="col">Jenis Persembahan</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Persembahan Natura (dinilai dengan Uang)</td>
              <td id="uang">{{ formatUang(persNatura()) }}</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Persembahan Khusus</td>
              <td id="uang">{{ formatUang(persKhusus()) }}</td>
            </tr>

            <tr class="table-secondary">
              <th colspan="2" scope="row">Jumlah</th>

              <th id="uang">{{ formatUang(totalTableB()) }}</th>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table">
              <th scope="col" colspan="3">C. Penerimaan Dana Pelayanan</th>
            </tr>
            <tr class="table-success">
              <th scope="col">No</th>
              <th scope="col">Jenis Persembahan</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Persepuluhan</td>
              <td id="uang">{{ formatUang(danaPelayanan()) }}</td>
            </tr>

            <tr class="table-secondary">
              <th colspan="2" scope="row">Jumlah</th>

              <th id="uang">{{ formatUang(danaPelayanan()) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Line2 -->
    <div class="row justify-content-md-center">
      <div class="col col-lg-6 justify-content-md-center">
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table">
              <th scope="col" colspan="3">Setoran ke Kas Sinode</th>
            </tr>
            <tr class="table-success">
              <th scope="col">No</th>
              <th scope="col">Jenis Setoran</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>15 % Total Penerimaan A</td>
              <td id="uang">{{ formatUang(setoranA()) }}</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>10 % Total Penerimaan B</td>
              <td id="uang">{{ formatUang(setoranB()) }}</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>10 % Total Penerimaan C</td>
              <td id="uang">{{ formatUang(setoranC()) }}</td>
            </tr>

            <tr class="table-secondary">
              <th colspan="2" scope="row">Jumlah</th>
              <th id="uang">{{ formatUang(jumlahSetSinodeA()) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col col-lg-6 justify-content-md-center">
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table">
              <th scope="col" colspan="3">Setoran ke kas THT dan Kesrah</th>
            </tr>
            <tr class="table-success">
              <th scope="col">No</th>
              <th scope="col">Gembala Jemaat</th>
              <th scope="col">Perpuluhan Gembala</th>
              <th scope="col">Iuran Pekerja 2% dari Nafkah</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Gembala 1</td>
              <td id="uang">0</td>
              <td id="uang">0</td>
              <td id="uang">0</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Gembala 2</td>
              <td id="uang">0</td>
              <td id="uang">0</td>
              <td id="uang">0</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Gembala 3</td>
              <td id="uang">0</td>
              <td id="uang">0</td>
              <td id="uang">0</td>
            </tr>

            <tr class="table-secondary">
              <th colspan="2" scope="row">Jumlah</th>
              <th id="uang">0</th>
              <th id="uang">0</th>
              <th id="uang">0</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";
export default {
  name: "SinodeKlasis",

  components: {
    VueMonthlyPicker
  },
  data() {
    return {
      selectedMonth: ""
    };
  },
  methods: {
    formatUang(data) {
      // console.log(data, "dataa");
      let uang = "";
      data = data.toString();
      for (let i = 0; i < data.length; i++) {
        if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
        } else {
          uang += data[i];
        }
      }
      return uang;
    },
    perpuluhan() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 8) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    hariMinggu() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 1) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    syukuran() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 9) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    rumahTangga() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 2) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    hariRaya() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 3) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    ibadahPsm() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 6) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    ibadahPkm() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 5) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    ibadahPkw() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 4) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    ibadahPkp() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 7) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    ibadahHuluHasil() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 10) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    ibadahHuluAdonan() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 11) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    jumlahPersemBulanan() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.KelompokMAId == 1) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    danaPelayanan() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 12) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    persKhusus() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 31) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    persNatura() {
      let hasil = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 32) {
          hasil += item.jumlah;
        }
      });
      return hasil;
    },
    totalTableB() {
      let hasil = this.persKhusus() + this.persNatura();

      return hasil;
    },
    setoranA() {
      let hasil = this.jumlahPersemBulanan() * 0.15;
      return hasil;
    },
    setoranB() {
      let hasil = this.totalTableB() * 0.1;
      return hasil;
    },
    setoranC() {
      let hasil = this.danaPelayanan() * 0.1;
      return hasil;
    },
    jumlahSetSinodeA() {
      let hasil = this.setoranA() + this.setoranB() + this.setoranC();
      return hasil;
    }
  },
  computed: {
    transaksiUser() {
      return this.$store.state.transaksiUser;
    }
  },
  created() {
    this.$store.dispatch("getTransaksiUser");
  }
};
</script>

<style scoped>
.title {
  font-weight: 700;
  padding: 0 15px;
  font-size: 40px;
  margin: 25px 0;
}
.SinodeKlasis {
  padding: 20px;
}
.periode {
  font-size: 18px;
}
table {
  font-size: 14px;
  padding: 0;
}
#uang {
  text-align: right;
}
td {
  text-align: left;
}
td,
th {
  justify-items: left;
  align-items: center;
  align-self: center;
  padding: 1px 2px;
}

th {
  text-align: center;
}
#pernerimaanBulanan {
  max-height: 200px;
  overflow: auto;
}

@media screen and (max-width: 800px) {
  table {
    /* font-size: 10px; */
    font-size: 70%;
    background-color: #eee;

    border: 1px dotted black;
    /* overflow: auto; */
    overflow-y: auto;
  }
  #tableNafkah {
    /* font-size: 10px; */
    /* font-size: 60%; */
    background-color: #eee;
    border: 1px dotted black;
    /* overflow: auto; */
    overflow-y: auto;
  }
}
</style>