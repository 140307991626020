import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login.vue"
import InpuTransaksi from "../views/InputTransaksi.vue"
import LaporanLokal from "../views/LaporanLokal.vue"
import SinodeKlasis from "../views/SinodeKlasis.vue"
import LapKasGereja from "../views/LapKasGereja.vue"
import AllTransaksi from "../views/AllTransaksi.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/input',
    name: 'InpuTransaksi',
    component: InpuTransaksi,
    meta: { requiresAuth: true }
  },
  {
    path: '/report/lokal',
    name: 'LaporanLokal',
    component: LaporanLokal,
    meta: { requiresAuth: true }
  },
  {
    path: '/report/kasgereja',
    name: 'LapKasGereja',
    component: LapKasGereja,
    meta: { requiresAuth: true }
  },
  {
    path: '/report/transaksi',
    name: 'AllTransaksi',
    component: AllTransaksi,
    meta: { requiresAuth: true }
  },
  {
    path: '/report/sinode',
    name: 'SinodeKlasis',
    component: SinodeKlasis,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router
