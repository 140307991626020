<template>
  <footer class="Footer">Copyright © Otniel Pangkung & Brian Thomas</footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.Footer {
  margin-top: 10mm;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: rgb(221, 50, 50);
}
</style>