<template>
  <div class="LaporanLokal justify-content-md-center">
    <div class="title">LAPORAN JEMAAT LOKAL</div>
    <!-- Line1 -->
    <div class="headLine row justify-content-md-around">
      <div class="col col-lg-2 justify-content-md-around">
        <!-- <p>Kelas Pekerja</p> -->
        <label class="my-1 mr-2" for="Username">Kelas Pekerja</label>
        <div class="input-group">
          <select class="form-control" v-model="kelasPekerja">
            <option v-for="kelas in this.kelas" :key="kelas" :value="kelas">{{kelas}}</option>
          </select>
        </div>
      </div>
      <div class="col col-lg-2 justify-content-md-center mb-5">
        <p>Periode:</p>
        <vue-monthly-picker v-model="selectedMonth"></vue-monthly-picker>
      </div>
    </div>
    <!-- Line2 -->
    <div class="row justify-content-md-center">
      <div class="col col-lg-6 justify-content-md-center">
        <table class="table table-hover table-bordered" id="pemasukanTable">
          <thead>
            <tr class="table-primary">
              <th scope="col">NO.</th>
              <th scope="col">Jenis Pemasukan</th>
              <th scope="col">Nilai</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">A1</th>
              <th colspan id>Persembahan</th>
            </tr>
            <tr v-for="ma in persembahanMa() " :key="ma.id">
              <th scope="row">{{ma.code}}</th>
              <td>{{ma.namaMataAnggaran}}</td>
              <td id="uang">{{ formatUang (getJumlah(ma[`Transaksis`]))}}</td>
              <td></td>
            </tr>
            <tr class="table-secondary">
              <th scope="row" colspan="2">Jumlah</th>
              <td></td>
              <th id="uang">{{ formatUang( getTotalA1(this.transaksiUser)) }}</th>
            </tr>
            <tr>
              <th scope="row">A2</th>
              <th colspan id>Persembahan Lain - Lain</th>
            </tr>
            <tr v-for="ma in persembahanLainMa() " :key="ma.id">
              <th scope="row">{{ma.code}}</th>
              <td>{{ma.namaMataAnggaran}}</td>
              <td id="uang">{{ formatUang (getJumlah(ma[`Transaksis`]))}}</td>
              <td></td>
            </tr>
            <tr class="table-secondary">
              <th scope="row" colspan="2">Jumlah</th>
              <th id="uang">{{ formatUang( getTotalA2()) }}</th>
              <td></td>
            </tr>
            <tr class="table-secondary">
              <td></td>
              <th scope="row" colspan="2">Total (A1 + A2)</th>
              <th id="uang">{{ formatUang( `${getTotalA1(this.transaksiUser) + getTotalA2()}`) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col col-lg-6 justify-content-md-center">
        <!-- Setoran Sinode -->
        <table class="table table-hover">
          <thead>
            <tr class="table-success">
              <th scope="col">NO.</th>
              <th scope="col">Jenis Pemasukan</th>
              <th scope="col">Nilai</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">B1</th>
              <th>I. Setoran ke Sinode dan Klasis ( Uang Tunai )</th>
            </tr>
            <tr>
              <th></th>
              <td>a. Setoran Sinode 15% x A1 = 15% x {{ formatUang( getTotalA1(this.transaksiUser)) }}</td>
              <td id="uang">{{ formatUang(` ${getTotalA1(this.transaksiUser)*0.15 }` ) }}</td>
              <td></td>
            </tr>
            <tr>
              <th></th>
              <td>a. Setoran Klasis 5% x A1 = 15% x {{ formatUang( getTotalA1(this.transaksiUser)) }}</td>
              <td id="uang">{{ formatUang(` ${getTotalA1(this.transaksiUser)*0.05 }` ) }}</td>
              <td></td>
            </tr>
            <tr class="table-secondary">
              <th colspan="2">Jumlah(B1)</th>
              <td></td>
              <th
                id="uang"
              >{{ formatUang(` ${getTotalA1(this.transaksiUser)*0.05 +( getTotalA1(this.transaksiUser)*0.15) }` ) }}</th>
            </tr>
          </tbody>
        </table>
        <!-- Alokasi Biaya Oprasional -->
        <table class="table table-hover">
          <thead>
            <tr class="table-success">
              <th scope="col">NO.</th>
              <th scope="col">Jenis Pemasukan</th>
              <th scope="col">Nilai</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">B1</th>
              <th>II. Alokasi Biaya Operasional</th>
            </tr>
            <tr>
              <th></th>
              <td>Alokasi Biaya Operasional 0% 10 jt;10jt 15% 25 Jt ; 25 jt 20% 40jt ; 25% > 40jt</td>
              <td id="uang">{{ formatUang(biayaOperasional() )}}</td>
              <td></td>
            </tr>
            <tr class="table-secondary">
              <th colspan="2">Jumlah(B1)</th>
              <td></td>
              <th id="uang">{{ formatUang(biayaOperasional() )}}</th>
            </tr>
            <tr class="table-secondary">
              <th colspan="2">NAFKAH PEKERJA (BRUTO) A1-B1-B2</th>
              <td></td>
              <th
                id="uang"
              >{{ formatUang(`${ getTotalA1(this.transaksiUser) - biayaOperasional() - (getTotalA1(this.transaksiUser)*0.05 +( getTotalA1(this.transaksiUser)*0.15)) }` )}}</th>
            </tr>
          </tbody>
        </table>
        <!-- Table A3 -->
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table-success">
              <th scope="col">NO.</th>
              <th scope="col">Penearimaan Persembahan Khusus</th>
              <th scope="col">Nilai</th>
              <th scope="col">Perpuluhan</th>
              <th scope="col">Sinode</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">A3</th>
              <th>II. Alokasi Biaya Operasional</th>
            </tr>
            <tr>
              <th></th>
              <td>Persembahan Khusus</td>
              <td id="uang">{{ formatUang (persembahanKhusus())}}</td>
              <td id="uang">{{ formatUang(perpuluhanKhusus()) }}</td>
              <td id="uang">{{ formatUang(sinodeKhusus()) }}</td>
              <td id="uang">{{ formatUang(jumlahKhusus()) }}</td>
              <!-- <td id="uang">{{ formatUang() }}</td> -->
            </tr>
            <tr class="table-secondary">
              <th colspan="2">Jumlah(A3)</th>
              <th id="uang">{{ formatUang (persembahanKhusus())}}</th>
              <th id="uang">{{ formatUang(perpuluhanKhusus()) }}</th>
              <th id="uang">{{ formatUang(sinodeKhusus()) }}</th>
              <th id="uang">{{ formatUang(jumlahKhusus()) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- line3 -->
    <div class="row justify-content-md-center">
      <table class="table table-hover table-bordered" id="tableNafkah">
        <thead>
          <tr class="table-success">
            <th rowspan="2">C</th>
            <th rowspan="2">NAFKAH PEKERJA</th>
            <th rowspan="2">Nafkah (%)</th>
            <th rowspan="2">100%</th>
            <th colspan="4">Pengurangan</th>
            <th rowspan="2">NAFKAH PEKERJA (NET)</th>
          </tr>
          <tr class="table-success">
            <th>Perpuluhan</th>
            <th>SubsidiSilang (15%)</th>
            <th>Iuran Pekerja (2%)</th>
            <th>Natura / Persembahan Khusus</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row"></th>
            <td>Gembala 1</td>
            <td id="uang">{{persenNafkah1 () }}</td>
            <td id="uang">{{ formatUang (fullNafkah1()) }}</td>
            <td id="uang">{{formatUang (perpuluhan1())}}</td>
            <td id="uang">{{formatUang ( subsidisilang1())}}</td>
            <td id="uang">{{formatUang ( iuaran1())}}</td>
            <td id="uang">{{formatUang ( natura1())}}</td>
            <td id="uang">{{formatUang ( netNafkah1())}}</td>
          </tr>
          <tr>
            <th scope="row"></th>
            <td>Gembala 2</td>
            <td id="uang">{{persenNafkah2 () }}</td>
            <td id="uang">{{ formatUang (fullNafkah2()) }}</td>
            <td id="uang">{{formatUang (perpuluhan2())}}</td>
            <td id="uang">{{formatUang ( subsidisilang2())}}</td>
            <td id="uang">{{formatUang ( iuaran2())}}</td>
            <td id="uang">{{formatUang ( natura2())}}</td>
            <td id="uang">{{formatUang ( netNafkah2())}}</td>
          </tr>
          <tr class="table-secondary">
            <th scope="row" colspan="8">Total Nafkah Pekerja</th>
            <th id="uang">{{formatUang ( totalNafkahNet())}}</th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-5"></div>
  </div>
</template>

<script>
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
  name: "LaporanLokal",
  data() {
    return {
      selectedMonth: "",
      kelasPekerja: "",
      kelas: ["12", "12B", "12C", "12D", "12E"]
    };
  },

  components: {
    VueMonthlyPicker
  },

  methods: {
    formatUang(data) {
      // console.log(data, "dataa");
      let uang = "";
      data = data.toString();
      for (let i = 0; i < data.length; i++) {
        if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
        } else {
          uang += data[i];
        }
      }
      return uang;
    },
    persembahanMa() {
      let hasil = [];
      this.mataAnggaran.map(data => {
        if (data.KelompokMAId == 1) {
          hasil.push(data);
        }
      });
      return hasil;
    },
    persembahanLainMa() {
      let hasil = [];
      this.mataAnggaran.map(data => {
        if (data.KelompokMAId == 2) {
          hasil.push(data);
        }
      });
      return hasil;
    },

    persembahanKhusus() {
      let jumlah = 0;
      this.transaksiUser?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal && item.MataAnggaranId == 31) {
          jumlah += item.jumlah;
        }
      });
      return jumlah;
    },
    sinodeKhusus() {
      let hasil = this.persembahanKhusus() * 0.1;
      return hasil;
    },
    perpuluhanKhusus() {
      let hasil = (this.persembahanKhusus() - this.sinodeKhusus()) * 0.1;
      return hasil;
    },
    jumlahKhusus() {
      let hasil = this.sinodeKhusus() + this.perpuluhanKhusus();
      return hasil;
    },
    getTotalA1(data) {
      let total = 0;
      data?.map(item => {
        // console.log(item);
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (item.KelompokMAId == 1 && periode == tanggal) {
          total += item.jumlah;
        }
      });
      return total;
    },
    getTotalA2() {
      let total = 0;
      this.transaksiUser?.map(item => {
        // console.log(item);
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (item.KelompokMAId == 2 && periode == tanggal) {
          total += item.jumlah;
        }
      });
      return total;
    },
    getJumlah(data) {
      //   console.log("jugaa");
      let jumlah = 0;
      //   console.log(data, "dataaa------");
      data?.map(item => {
        const tanggal = moment(item.tanggalTransaksi).format("MM-YYYY");
        const periode = moment(this.selectedMonth).format("MM-YYYY");
        if (periode == tanggal) {
          jumlah += item.jumlah;
        }
      });
      return jumlah;
    },
    biayaOperasional() {
      let hasil = 0;
      if (this.getTotalA1(this.transaksiUser) * 0.15 < 10000001) {
        hasil = this.getTotalA1(this.transaksiUser) * 0.1;
      } else if (this.getTotalA1(this.transaksiUser) < 25000001) {
        hasil = this.getTotalA1(this.transaksiUser) * 0.15;
      } else if (this.getTotalA1(this.transaksiUser) < 40000001) {
        hasil = this.getTotalA1(this.transaksiUser) * 0.2;
      } else {
        hasil = this.getTotalA1(this.transaksiUser) * 0.25;
      }
      return hasil;
    },
    persenNafkah1() {
      let persen = 0;
      if (this.kelasPekerja == "12") {
        persen = 100;
      } else if (this.kelasPekerja == "12A") {
        persen = 60;
      } else if (this.kelasPekerja == "12B") {
        persen = 40;
      } else if (this.kelasPekerja == "12C") {
        persen = 41;
      } else if (this.kelasPekerja == "12D") {
        persen = 50;
      } else if (this.kelasPekerja == "12E") {
        persen = 32.5;
      }
      return persen;
    },
    persenNafkah2() {
      let persen = 0;
      if (this.kelasPekerja == "12A") {
        persen = 40;
      } else if (this.kelasPekerja == "12B") {
        persen = 35;
      } else if (this.kelasPekerja == "12C") {
        persen = 39;
      } else if (this.kelasPekerja == "12D") {
        persen = 30;
      } else if (this.kelasPekerja == "12E") {
        persen = 27.5;
      }
      return persen;
    },
    fullNafkah1() {
      let hasil = (this.brutoNafkahPekerja() * this.persenNafkah1()) / 100;
      return hasil;
    },
    fullNafkah2() {
      let hasil = (this.brutoNafkahPekerja() * this.persenNafkah2()) / 100;
      return hasil;
    },
    perpuluhan1() {
      let hasil =
        this.brutoNafkahPekerja() * (this.persenNafkah1() / 100) * 0.1;
      return hasil;
    },
    perpuluhan2() {
      let hasil =
        this.brutoNafkahPekerja() * (this.persenNafkah2() / 100) * 0.1;
      return hasil;
    },
    brutoNafkahPekerja() {
      let hasil =
        this.getTotalA1(this.transaksiUser) -
        this.biayaOperasional() -
        (this.getTotalA1(this.transaksiUser) * 0.05 +
          this.getTotalA1(this.transaksiUser) * 0.15);

      return hasil;
    },
    subsidisilang1() {
      let hasil = 0;
      let penm =
        this.brutoNafkahPekerja() * (this.persenNafkah1() / 100) -
        this.brutoNafkahPekerja() * (this.persenNafkah1() / 100) * 0.1;
      //   console.log(penm, "penm subsidisilang");
      if (penm > 2500000) {
        hasil = (penm - 2500000) * 0.15;
      } else hasil = 0;
      return hasil;
    },
    subsidisilang2() {
      let hasil = 0;
      let penm =
        this.brutoNafkahPekerja() * (this.persenNafkah2() / 100) -
        this.brutoNafkahPekerja() * (this.persenNafkah2() / 100) * 0.1;
      //   console.log(penm, "penm subsidisilang");
      if (penm > 2500000) {
        hasil = (penm - 2500000) * 0.15;
      } else hasil = 0;
      return hasil;
    },
    iuaran1() {
      let hasil =
        (this.brutoNafkahPekerja() * (this.persenNafkah1() / 100) -
          this.brutoNafkahPekerja() * (this.persenNafkah1() / 100) * 0.1) *
        0.02;
      return hasil;
    },
    iuaran2() {
      let hasil =
        (this.brutoNafkahPekerja() * (this.persenNafkah2() / 100) -
          this.brutoNafkahPekerja() * (this.persenNafkah2() / 100) * 0.1) *
        0.02;
      return hasil;
    },
    natura1() {
      let hasil = (this.jumlahKhusus() * this.persenNafkah1()) / 100;
      return hasil;
    },
    netNafkah1() {
      let hasil =
        this.fullNafkah1() -
        this.perpuluhan1() -
        this.subsidisilang1() -
        this.iuaran1() -
        this.natura1() +
        (this.persenNafkah1() / 100) * this.persembahanKhusus();
      return hasil;
    },
    natura2() {
      let hasil = (this.jumlahKhusus() * this.persenNafkah2()) / 100;
      return hasil;
    },
    netNafkah2() {
      let hasil =
        this.fullNafkah2() -
        this.perpuluhan2() -
        this.subsidisilang2() -
        this.iuaran2() -
        this.natura2() +
        (this.persenNafkah2() / 100) * this.persembahanKhusus();
      return hasil;
    },
    totalNafkahNet() {
      let hasil = this.netNafkah1() + this.netNafkah2();
      return hasil;
    }
  },
  created() {
    this.$store.dispatch("getMataAnggaran");
    this.$store.dispatch("getTransaksiUser");
  },
  computed: {
    mataAnggaran() {
      return this.$store.state.mataAnggaran;
    },
    transaksiUser() {
      return this.$store.state.transaksiUser;
    }
  }
};
</script>

<style scoped>
.title {
  font-weight: 700;
  padding: 0 15px;
  font-size: 40px;
  margin: 25px 0;
  margin-bottom: 20px;
}
.headLine {
  margin-top: 10px;
}
.headLine div p {
  font-size: 16px;
  font-weight: bold;
}
label {
  font-size: 16px;
  font-weight: bold;
}
.LaporanLokal {
  padding: 30px;
}
table {
  font-size: 14px;
  padding: 0;
}
option {
  font-weight: bold;
}
#uang {
  text-align: right;
}
td {
  text-align: left;
}
td,
th {
  align-items: center;
  align-self: center;
  padding: 2px 2px;
}

th {
  text-align: center;
}

@media screen and (max-width: 800px) {
  table {
    /* font-size: 10px; */
    font-size: 70%;
    background-color: #eee;

    border: 1px dotted black;
    /* overflow: auto; */
    overflow-y: auto;
  }
  #tableNafkah {
    /* font-size: 10px; */
    /* font-size: 60%; */
    background-color: #eee;
    border: 1px dotted black;
    /* overflow: auto; */
    overflow-y: auto;
  }
}
</style>