import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../API/axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jenisTransaksi: [],
    mataAnggaran: [],
    transaksiUser: [],
    namaJemaat: "",
    isLogin: false
  },
  mutations: {

    setJenisTrasaksi(state, data) {
      state.jenisTransaksi = data
    },
    setTransaksiUser(state, data) {
      state.transaksiUser = data
    },
    setMataAnggaranList(state, data) {
      state.mataAnggaran = data
    },
    setUserLogin(state, data) {
      state.isLogin = data
    },
    setNamaJemaat(state, nama) {
      state.namaJemaat = nama
    }
  },
  actions: {
    getTransaksiUser(context) {
      return axios.get("transaksi/jemaat", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setTransaksiUser", data)
      }).catch(err => {
        console.log(err);
      })
    },
    getJenisTransaksi(context) {
      return axios.get("/database/jenistransaksi")
        .then(({ data }) => {
          context.commit("setJenisTrasaksi", data)
        })
        .catch(err => {
          console.log(err);
        })
    },
    getMataAnggaran(context) {
      return axios.get("/database/mataanggaran", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      })
        .then(({ data }) => {
          context.commit("setMataAnggaranList", data)
          // console.log(data);
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
  modules: {
  }
})
