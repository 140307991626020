<template>
  <div class="LapKasGereja">
    <div class="row justify-content-md-center">
      <div class="col col-lg-6">
        <!-- Title -->
        <div class="title col justify-content-md-center mt-1">
          <div class="title">Laporan Kas Gereja</div>
          <div class="periode row justify-content-md-center mt-1">
            <div class="col-lg-5">
              Periode:
              <vue-monthly-picker v-model="selectedMonth"></vue-monthly-picker>
            </div>
          </div>
        </div>
        <!-- Table -->
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table-success">
              <th scope="col">No</th>
              <th scope="col">Keterangan</th>
              <th scope="col">Jumlah</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="uang"></td>
              <th scope="row" colspan="2">Saldo Awal</th>

              <td id="uang">0</td>
            </tr>
            <tr>
              <td id="uang"></td>
              <td scope="row" colspan="2">Penerimaan</td>

              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Persembahan Dana Kas Gereja</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Dana Pelayanan 80%</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Alokasi Dana Operasional 15%</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Persembahan Transport Guru SM</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr class="table-secondary">
              <th id="uang"></th>
              <th colspan="2" scope="row">Saldo Awal + Penerimaan</th>
              <th id="uang">0</th>
            </tr>
            <tr>
              <td id="uang"></td>
              <th scope="row" colspan="2">Pengeluaran</th>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Transport Pemain Musik</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Listrik & PDAM</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Transport Koster</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Transport Guru Sekolah Minggu</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Indihome</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Transport ke Sinode</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>Biaya Lain-lain (Pos PI Translalili)</td>
              <td id="uang">0</td>
              <td id="uang"></td>
            </tr>
            <tr class="table-secondary">
              <th id="uang"></th>
              <th colspan="2" scope="row">Total Pengeluaran</th>
              <th id="uang">0</th>
            </tr>
            <tr class="table-secondary">
              <th id="uang"></th>
              <th colspan="2" scope="row">Saldo Akhir</th>
              <th id="uang">0</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VueMonthlyPicker from "vue-monthly-picker";

export default {
  name: "LapKasGereja",
  components: {
    VueMonthlyPicker
  },
  data() {
    return {
      selectedMonth: ""
    };
  }
};
</script>

<style scoped>
.LapKasGereja {
  padding: 20px;
}
.title {
  font-weight: 700;
  padding: 0 15px;
  font-size: 30px;
  margin: 25px 0;
}
.periode {
  font-size: 18px;
}
td {
  text-align: left;
}
table th,
td {
  padding: 0;
  font-size: 14px;
}
tbody td {
  text-align: left;
}
#uang {
  text-align: right;
}
</style>
