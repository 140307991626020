<template>
  <div class="AllTransakss">
    <div class="title">Daftar Transaksi</div>

    <div class="row justify-content-md-center mt-1">
      <div class="col col-lg-10">
        <table class="table table-hover table-bordered">
          <thead>
            <tr class="table-success">
              <th scope="col">No</th>
              <th scope="col">Nama Transaksi</th>
              <th scope="col">Tanggal Transaksi</th>
              <th scope="col">Jenis Transaksi</th>
              <th scope="col">Mata Anggaran</th>
              <th scope="col">Jumlah</th>
              <th scope="col">status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list in transaksiUser" :key="list.id">
              <th scope="row">{{list.id}}</th>
              <td>{{list.namaTransaksi}}</td>
              <td>{{list.tanggalTransaksi.slice(0,10)}}</td>
              <td>{{list.KelompokMA.namaKelMa}}</td>
              <td>{{list.MataAnggaran.namaMataAnggaran}}</td>
              <td id="uang">{{ formatUang(list.jumlah)}}</td>
              <td>{{list.status}}</td>
              <td class="action">
                <a @click.prevent="deleteTransaksi(list.id)">
                  <i class="fas fa-trash-alt"></i>
                </a>

                <i class="fas fa-edit"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- {{transaksiUser}} -->
  </div>
</template>

<script>
import axios from "../API/axios";
import Swal from "sweetalert2";

export default {
  name: "AllTransaksi",
  computed: {
    transaksiUser() {
      return this.$store.state.transaksiUser;
    }
  },
  created() {
    this.$store.dispatch("getTransaksiUser");
  },
  methods: {
    formatUang(data) {
      // console.log(data, "dataa");
      let uang = "";
      data = data.toString();
      for (let i = 0; i < data.length; i++) {
        if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
        } else {
          uang += data[i];
        }
      }
      return uang;
    },
    deleteTransaksi(id) {
      return axios
        .delete(`/transaksi/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(data => {
          Swal.fire({
            title: "Berhasil",
            text: "Data Berhasil Ditambahkan",
            icon: "success",
            confirmButtonText: "Ok"
          });
          this.$store.dispatch("getTransaksiUser");
        })
        .catch(err => {
          Swal.fire("Maaf", "Anda Tidak Dapat Menghapus Data Anda", "error");
        });
    }
  }
};
</script>

<style scoped>
.title {
  font-weight: 700;
  padding: 0 15px;
  font-size: 30px;
  margin: 25px 0;
}
.periode {
  font-size: 18px;
}
td {
  text-align: left;
}
.action {
  text-align: center;
  font-size: 20px;
}

.svg-inline--fa {
  margin-right: 5px;
}
table th,
td {
  padding: 8px 0;
  font-size: 14px;
}
tbody td {
  text-align: left;
}
#uang {
  text-align: right;
}
</style>