<template>
  <div class="container-fluid" id="app">
    <Navbar />
    <!-- <Footer /> -->
    <div></div>

    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footers";

export default {
  components: { Navbar, Footer }
};
</script>

<style >
#app {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f2f2f2;
  min-height: 100vh;
  padding: 0;
}
</style>
