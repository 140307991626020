<template>
  <h1>Selamat Datang Ben..!! Pilih menu di Navbar</h1>
</template>

<script>
import Swal from "sweetalert2";
export default {
  methods: {
    showAlert() {
      // const Swal = require("sweetalert2");
      Swal.fire({
        title: "Error!",
        text: "Do you want to continue",
        icon: "success",
        confirmButtonText: "Ok"
      });
    }
  }
};
</script>